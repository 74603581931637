/* SPDX-License-Identifier: AGPL-3.0-or-later */
/* global searxng */
(function (w, d, searxng) {
  "use strict";
  function addClickAndKeyboardListener (element, callback) {
    for (const eventName of ["click", "keydown"]) {
      element.addEventListener(eventName, function (event) {
        if (eventName === "keydown" && !["Enter", "Spacebar", " "].includes(event.key)) {
          return;
        }
        callback(event);
      });
    }
  }

  function getCookie (cookieName) {
    const cookies = d.cookie?.split(";").map((cookie) => cookie.trim().split("="));
    const [, cookieValue] = cookies?.find(([name]) => name === cookieName) ?? [];
    return cookieValue;
  }

  function setCookie (cookieName, cookieValue, currentPathOnly = true) {
    if (!cookieName) {
      return;
    }
    d.cookie = `${cookieName}=${cookieValue}; max-age=34560000; domain=.${d.location.hostname};${currentPathOnly ? ` path=${w.location.pathname};` : ""}`;
  }

  searxng.ntUtils = {
    addClickAndKeyboardListener,
    getCookie,
    setCookie
  };
})(window, document, searxng);
